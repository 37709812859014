declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

interface IDataLayerEvent {
  eventName: string;
  data: any;
}

export const dataLayerPush = async ({ eventName, data }: IDataLayerEvent) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    const user_id = localStorage.getItem('user_id') || 'guest';

    window.dataLayer.push({ ecommerce: null });
    setTimeout(() => {
      window.dataLayer.push({
        event: eventName,
        ecommerce: data,
        user_properties: {
          user_id,
        },
      });
    }, 10);
  }
};

/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext, useEffect, useState } from 'react';
import { useLoading } from 'hooks/useLoading';
import { useTranslation } from 'next-i18next';
import useRtl from 'hooks/useRtl';
import CommonHeader from 'components/Header/CommonHeader';
import Header from 'components/Header/LogistrationHeader';
import dynamic from 'next/dynamic';
import SideBar from 'components/SideBar';
import { useRouter } from 'next/router';
import { MetaTag, OpenGraph, Twitter } from 'next-seo/lib/types';
import CustomModal from 'components/Modal/priceUpdateModal';
import { Button } from 'components/Button';
import { ClosePrivacyIcon } from 'components/SvgIcons/ClosePrivacyIcon';
import { DeviceDetector } from 'utils/dd';
import clsx from 'clsx';

interface Page {
  children: React.ReactNode;
  showFooter?: boolean;
  isLogin?: boolean;
  className?: string;
  showSideBar?: boolean;
  subHeader?: boolean;
  sideBarTitle?: string;
  sideBarBreadcrumb?: string;
  t?: any;
  isRtl?: boolean;
  title?: string;
  description?: string;
  openGraph?: OpenGraph;
  metaTags?: MetaTag[];
  twitter?: Twitter;
  canonical?: string;
  isLoadingPage?: boolean;
  isBot?: boolean;
  sideBarClassName?: string;
  sideBarBackClassName?: string;
  backColor?: string;
}

const styles = {
  background: '#F7F7F8',
};

const Layout = ({
  children,
  showFooter = true,
  isLogin = false,
  className = 'pb-0',
  showSideBar = false,
  subHeader = true,
  sideBarTitle = '',
  sideBarBreadcrumb = '',
  title,
  description,
  openGraph,
  metaTags,
  canonical,
  twitter,
  isLoadingPage = false,
  isBot = false,
  sideBarClassName = 'bg-[#fff] px-2',
  sideBarBackClassName = 'w-full mb-2 grid border-b grid-cols-[10%_80%] items-center px-2 h-[40px] bg-[#fff] text-gray-900',
  backColor = '#595959',
}: Page) => {
  const [loading, setLoading] = useLoading();
  const isRtl = useRtl();
  const { t } = useTranslation('common');
  const router = useRouter();
  const isMobile = useContext(DeviceDetector).isMobile;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const popupSeen = localStorage.getItem('popupSeen');
    if (!JSON.parse(popupSeen)) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [router.pathname]);

  const CommonFooter = dynamic(() => import('components/Footer/CommonFooter'), { ssr: false });
  const Loader = dynamic(() => import('components/Loader'), { ssr: false });
  const CookiePolicyPopup = dynamic(() => import('components/CookiePolicyPopup'), { ssr: false });

  useEffect(() => {
    if (!isBot) {
      const onStart = () => {
        setLoading(true);
      };
      const onComplete = () => {
        setLoading(false);
      };

      const onError = () => {
        setLoading(false);
      };

      router.events.on('routeChangeStart', onStart);
      router.events.on('routeChangeComplete', onComplete);
      router.events.on('routeChangeError', onError);

      return () => {
        router.events.off('routeChangeStart', onStart);
        router.events.off('routeChangeComplete', onComplete);
        router.events.off('routeChangeError', onError);
      };
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('popupSeen', 'true');
  };

  const handleReview = () => {
    setIsVisible(false);
    localStorage.setItem('popupSeen', 'true');
    router.push('/support/privacy_policy_faq');
  };

  return (
    <div
      dir={isRtl ? 'rtl' : ' ltr'}
      style={router.pathname !== '/links' ? styles : { backgroundColor: 'white' }}
      className={`${className} flex flex-col min-h-screen`}
    >
      {!isBot && <CookiePolicyPopup />}
      <CustomModal />
      {isLogin &&
      router.pathname !== '/login' &&
      router.pathname !== '/login-with-number' &&
      router.pathname !== '/signup' ? (
        <Header t={t} />
      ) : (
        !isLoadingPage && (
          <CommonHeader
            title={title}
            description={description}
            metaTags={metaTags}
            openGraph={openGraph}
            t={t}
            subHeader={subHeader}
            canonical={canonical}
            twitter={twitter}
          />
        )
      )}
      {isLogin ? (
        children
      ) : showSideBar ? (
        <SideBar
          t={t}
          sideBarBackClassName={sideBarBackClassName}
          sideBarClassName={sideBarClassName}
          title={sideBarTitle}
          breadcrumb={sideBarBreadcrumb}
          backColor={backColor}
        >
          {children}
        </SideBar>
      ) : (
        children
      )}
      {router.pathname.includes('login') && <div className='mt-[4%]' />}
      {showFooter && !isBot && !isLoadingPage && <CommonFooter t={t} />}
      {isVisible && (
        <div
          className={clsx(
            'fixed bottom-0 left-0 w-full bg-white p-2 flex justify-between items-center z-[100] shadow-[0px_-3px_20px_3px_#00000024] px-2 2xl:px-[20%] md:px-[5%]',
            {
              'lg:px-[7%] 2xlll:px-[10%] 2xll:px-[12%]': !isRtl,
              'lg:px-[6%] 2xlll:px-[10%] 2xll:px-[12%]': isRtl,
            },
          )}
        >
          <div className='flex flex-col space-y-1'>
            <div className='flex justify-between items-center'>
              <p className='font-bold test-[16px]'>{t('privacy_policy_updated')}</p>
              {isMobile && (
                <button className='mt-2 rtl:mr-4' onClick={handleClose}>
                  <ClosePrivacyIcon />
                </button>
              )}
            </div>
            <p className='text-[14px] text-[#425563]'>{t('privacy_policy_updated_content')}</p>
            {isMobile && <Button title={t('review') || ''} onClick={handleReview} className='mt-2.5 px-2 font-bold' />}
          </div>

          {!isMobile && (
            <Button
              title={t('review') || ''}
              onClick={handleReview}
              className={clsx('mt-2.5 text-[14px] px-[3%] py-1', {
                'ml-[5%]': !isRtl,
                'mr-[15%]': isRtl,
              })}
            />
          )}

          {!isMobile && (
            <button
              className={clsx('mt-2 rtl:mr-4 absolute', {
                'right-6': !isRtl,
                'left-6': isRtl,
              })}
              onClick={handleClose}
            >
              <ClosePrivacyIcon />
            </button>
          )}
        </div>
      )}
      {loading && <Loader />}
    </div>
  );
};

export default Layout;

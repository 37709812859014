import { get_customer_data } from 'models/customer';
import { useQuery, QueryKey } from '@tanstack/react-query';
import useCookieStorage from 'hooks/useCookieStorage';
import useStoreView from 'hooks/useStoreView';
import { useMemo } from 'react';

export default function useCustomerQuery(options?: any) {
  const [isCustomerLoggedIn] = useCookieStorage('isCustomerLoggedIn', false);
  const [storeView] = useStoreView();

  const queryKey: QueryKey = useMemo(
    () => ['customer', storeView, isCustomerLoggedIn],
    [storeView, isCustomerLoggedIn],
  );

  return useQuery(queryKey, get_customer_data, {
    enabled: !!isCustomerLoggedIn,
    staleTime: 1000 * 60 * 10, // Adjusted for longer staleness
    cacheTime: 1000 * 60 * 30, // Cache for longer duration
    ...options,
  });
}

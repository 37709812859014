import React, { useEffect, startTransition } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { GetServerSideProps } from 'next';
import CmsBlocks from 'components/CMS/CmsBlocks';
import { checkServerSideDeviceTypeIsMobile } from 'utils/check-server-side-device-type-is-mobile';
import DetectUserAget from 'utils/ua';
import { useLoading } from 'hooks/useLoading';
import { cms, updateCmsResponseWithAlgolia } from '../models/cms';
import { getAlgolia } from '../models/algolia-client';
import { setSeoForGlopalPage } from 'utils/set-seo-fields-page';
import { trackPageEvent } from 'utils/content-squire';
import useCustomer from 'hooks/useCustomer';

interface IHome {
  homeCms: any;
  isMobileFlagFromServerSide: boolean;
  richTextData: any;
  isBot?: boolean;
  customerData?: any;
}

const Home = (props: IHome) => {
  const { homeCms, isBot } = props;
  const { customerData } = useCustomer();
  const isWindow = typeof window !== 'undefined';

  const [, setLoading] = useLoading();
  useEffect(() => {
    startTransition(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const Insider = window as Window | any;

    Insider.insider_object = Insider.insider_object || {};
    Insider.insider_object.page = {
      type: 'Home',
    };
    if (customerData) {
      Insider.insider_object.user = {
        uuid: String(customerData?.id),
        gdpr_optin: true,
        name: customerData?.firstname,
        phone_number: `+${customerData?.phone_number}`,
      };
    }

    if (isWindow) {
      trackPageEvent(window, 'HomePageView', {});
    }
  }, []);

  return (
    <div className='pb-0 bg-white min-h-screen'>
      <div className="relative w-full">
        <CmsBlocks 
          isBot={isBot} 
          cmsData={homeCms} 
          isMobileFlagFromServerSide={props.isMobileFlagFromServerSide} 
        />
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx: any) => {
  ctx?.res?.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');
  const cmsContent = await cms('home', ctx.req);
  const algolia = getAlgolia();
  const cmsDataWithALgoliaProducts = await updateCmsResponseWithAlgolia(cmsContent, algolia);

  const { isBot } = DetectUserAget(ctx);

  const pathLocale = ctx?.locale ?? 'en-sa';
  const url = `https://${ctx.req.headers.host}/${pathLocale}`;

  return {
    props: {
      layout: {
        ...setSeoForGlopalPage(url),
        title: cmsDataWithALgoliaProducts.meta_title,
        description: cmsDataWithALgoliaProducts.meta_description,
        metaTags: [
          {
            name: 'keywords',
            content: cmsDataWithALgoliaProducts.meta_keywords,
          },
        ],
      },
      homeCms: cmsDataWithALgoliaProducts,
      isBot,
      isMobileFlagFromServerSide: checkServerSideDeviceTypeIsMobile(ctx),
      ...(await serverSideTranslations(ctx.locale, ['common'])),
    },
  };
};

export default Home;
